import React, { useState, lazy, Suspense } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import AuthLayout from '../layout/AuthLayout';
import MainLayout from '../layout/MainLayout';
import LoadingIndicator from '../component/Loader';
import { ToastContainer } from 'react-toastify';

const Dashboard = lazy(() => import('../pages/main/icgms/Dashboard'));
const Client = lazy(() => import('../pages/main/icgms/registration/client/Index'));
const ClientCreate = lazy(() =>
  import('../pages/main/icgms/registration/client/Creation')
);
const ClientShow = lazy(() =>
  import('../pages/main/icgms/registration/client/Show')
);
const ClientEdit = lazy(() =>
  import('../pages/main/icgms/registration/client/Edit')
);
const ICGMS = lazy(() => import('../pages/main/icgms/registration/icgms/Index'));
const ICGMSCreate = lazy(() =>
  import('../pages/main/icgms/registration/icgms/Creation')
);
const ICGMSShow = lazy(() => import('../pages/main/icgms/registration/icgms/Show'));
const ICGMSEdit = lazy(() => import('../pages/main/icgms/registration/icgms/Edit'));
const InsuranceFinancier = lazy(() =>
  import('../pages/main/icgms/registration/insuranceFinancier/Index')
);
const InsuranceFinancierCreate = lazy(() =>
  import('../pages/main/icgms/registration/insuranceFinancier/Creation')
);
const InsuranceFinancierShow = lazy(() =>
  import('../pages/main/icgms/registration/insuranceFinancier/Show')
);
const InsuranceFinancierEdit = lazy(() =>
  import('../pages/main/icgms/registration/insuranceFinancier/Edit')
);
const Repairer = lazy(() =>
  import('../pages/main/icgms/registration/repairer/Index')
);
const RepairerCreate = lazy(() =>
  import('../pages/main/icgms/registration/repairer/Creation')
);
const RepairerShow = lazy(() =>
  import('../pages/main/icgms/registration/repairer/Show')
);
const RepairerEdit = lazy(() =>
  import('../pages/main/icgms/registration/repairer/Edit')
);
const RepairerMap = lazy(() =>
  import('../pages/main/icgms/registration/repairer/Mapped')
);
const RepairerReject = lazy(() =>
  import('../pages/main/icgms/registration/repairer/Reject')
);
const Surveyor = lazy(() =>
  import('../pages/main/icgms/registration/surveyor/Index')
);
const SurveyorCreate = lazy(() =>
  import('../pages/main/icgms/registration/surveyor/Creation')
);
const SurveyorShow = lazy(() =>
  import('../pages/main/icgms/registration/surveyor/Show')
);
const SurveyorEdit = lazy(() =>
  import('../pages/main/icgms/registration/surveyor/Edit')
);
const SurveyorMap = lazy(() =>
  import('../pages/main/icgms/registration/surveyor/Mapped')
);
const SurveyorReject = lazy(() =>
  import('../pages/main/icgms/registration/surveyor/Reject')
);
const Vehicle = lazy(() => import('../pages/main/icgms/master/vehicle/Index'));
const VehicleShow = lazy(() => import('../pages/main/icgms/master/vehicle/Show'));
const VehicleEdit = lazy(() => import('../pages/main/icgms/master/vehicle/Edit'));
const Location = lazy(() => import('../pages/main/icgms/master/location/Index'));
const Product = lazy(() => import('../pages/main/icgms/master/product/Index'));
const ProductShow = lazy(() => import('../pages/main/icgms/master/product/Show'));
const ProductEdit = lazy(() => import('../pages/main/icgms/master/product/Edit'));
const Billing = lazy(() => import('../pages/main/icgms/account/Bill'));
const Subscription = lazy(() => import('../pages/main/icgms/account/Subscription'));
const Settings = lazy(() => import('../pages/main/icgms/Settings'));
const Reports = lazy(() => import('../pages/main/icgms/Reports'));
const Login = lazy(() => import('../pages/auth/Login'));
const Forgot = lazy(() => import('../pages/auth/Forgot'));
const Reset = lazy(() => import('../pages/auth/ResetPassword'));
// Client
const UserCreation = lazy(() => import('../pages/main/client/registration/users/Creation'));
const UserGrid = lazy(() => import('../pages/main/client/registration/users/Grid'));
const SurveyorCreation = lazy(() => import('../pages/main/client/registration/surveyor/Creation'));
const SurveyorGrid = lazy(() => import('../pages/main/client/registration/surveyor/Grid'));
const RepairerCreation = lazy(() => import('../pages/main/client/registration/repairer/Creation'));
const RepairerGrid = lazy(() => import('../pages/main/client/registration/repairer/Grid'));
const NewClaim = lazy(() => import('../pages/main/client/motorClaims/Newclaim'));
const ClaimGrid = lazy(() => import('../pages/main/client/motorClaims/claimIndimation/ClaimverificationGrid'));
const ClaimVerify = lazy(() => import('../pages/main/client/motorClaims/claimIndimation/Claimverification'));
const ClaimCorrection = lazy(() => import('../pages/main/client/motorClaims/claimIndimation/ClaimverificationRe'));
const ClaimFormQC = lazy(() => import('../pages/main/client/motorClaims/claimIndimation/ClaimFormQC'));
const ClaimFormQCSurveyor = lazy(() => import('../pages/main/client/motorClaims/claimIndimation/ClaimFormQCSurveyor'));
const SurveyorReport = lazy(() => import('../pages/main/client/motorClaims/claimIndimation/SurveyorReport'));
const SurveyorReportRepair = lazy(() => import('../pages/main/client/motorClaims/claimIndimation/SurveyorReportforRepair'));
const RepairerReport = lazy(() => import('../pages/main/client/motorClaims/claimIndimation/RepairerReport'));
const CustomerDetails = lazy(() => import('../pages/main/client/motorClaims/claimIndimation/CustomerDetails'));
const CustomerInfo = lazy(() => import('../pages/main/client/motorClaims/claimIndimation/CustomerDet'));
const IntimationClaim = lazy(() => import('../pages/main/client/motorClaims/claimIndimation/IntimationClaim'));
const ClaimInsurer = lazy(() => import('../pages/main/client/motorClaims/claimIndimation/ClaimFormQCClaimIntimate'));
const CustomerPrint = lazy(() => import('../../src/pages/auth/PrintClaimform'));

export default () => {
  const [, setAuthenticated] = useState(false);
  return (
    <>
      <ToastContainer />
      <BrowserRouter>
        <Routes>
          <Route
            path='/'
            element={<AuthLayout setAuthenticated={setAuthenticated} />}
          >
            <Route
              index
              path='/'
              element={
                <Suspense fallback={<LoadingIndicator />}>
                  <Login />
                </Suspense>
              }
            />
            <Route
              path='/forgot'
              element={
                <Suspense fallback={<LoadingIndicator />}>
                  <Forgot />
                </Suspense>
              }
            />
            <Route
              path='/reset/:ID'
              element={
                <Suspense fallback={<LoadingIndicator />}>
                  <Reset />
                </Suspense>
              }
            />
          <Route
                path='/customerform'
                element={
                  <Suspense fallback={<LoadingIndicator />}>
                    <CustomerPrint />
                  </Suspense>
                }
              />
          </Route>
          <Route path='/admin' element={<MainLayout />}>
            <Route
              path='/admin/dashboard'
              element={
                <Suspense fallback={<LoadingIndicator />}>
                  <Dashboard />
                </Suspense>
              }
            />
            <Route
              path='/admin/client'
              element={
                <Suspense fallback={<LoadingIndicator />}>
                  <Client />
                </Suspense>
              }
            />
            <Route
              path='/admin/client/create'
              element={
                <Suspense fallback={<LoadingIndicator />}>
                  <ClientCreate />
                </Suspense>
              }
            />
            <Route
              path='/admin/client/show'
              element={
                <Suspense fallback={<LoadingIndicator />}>
                  <ClientShow />
                </Suspense>
              }
            />
            <Route
              path='/admin/client/edit'
              element={
                <Suspense fallback={<LoadingIndicator />}>
                  <ClientEdit />
                </Suspense>
              }
            />
            <Route
              path='/admin/icgms'
              element={
                <Suspense fallback={<LoadingIndicator />}>
                  <ICGMS />
                </Suspense>
              }
            />
            <Route
              path='/admin/icgms/create'
              element={
                <Suspense fallback={<LoadingIndicator />}>
                  <ICGMSCreate />
                </Suspense>
              }
            />
            <Route
              path='/admin/icgms/show'
              element={
                <Suspense fallback={<LoadingIndicator />}>
                  <ICGMSShow />
                </Suspense>
              }
            />
            <Route
              path='/admin/icgms/edit'
              element={
                <Suspense fallback={<LoadingIndicator />}>
                  <ICGMSEdit />
                </Suspense>
              }
            />
            <Route
              path='/admin/insurancefinancier'
              element={
                <Suspense fallback={<LoadingIndicator />}>
                  <InsuranceFinancier />
                </Suspense>
              }
            />
            <Route
              path='/admin/insurancefinancier/create'
              element={
                <Suspense fallback={<LoadingIndicator />}>
                  <InsuranceFinancierCreate />
                </Suspense>
              }
            />
            <Route
              path='/admin/insurancefinancier/show'
              element={
                <Suspense fallback={<LoadingIndicator />}>
                  <InsuranceFinancierShow />
                </Suspense>
              }
            />
            <Route
              path='/admin/insurancefinancier/edit'
              element={
                <Suspense fallback={<LoadingIndicator />}>
                  <InsuranceFinancierEdit />
                </Suspense>
              }
            />
            <Route
              path='/admin/repairer'
              element={
                <Suspense fallback={<LoadingIndicator />}>
                  <Repairer />
                </Suspense>
              }
            />
            <Route
              path='/admin/repairer/create'
              element={
                <Suspense fallback={<LoadingIndicator />}>
                  <RepairerCreate />
                </Suspense>
              }
            />
            <Route
              path='/admin/repairer/show'
              element={
                <Suspense fallback={<LoadingIndicator />}>
                  <RepairerShow />
                </Suspense>
              }
            />
            <Route
              path='/admin/repairer/edit'
              element={
                <Suspense fallback={<LoadingIndicator />}>
                  <RepairerEdit />
                </Suspense>
              }
            />
            <Route
              path='/admin/repairer/map'
              element={
                <Suspense fallback={<LoadingIndicator />}>
                  <RepairerMap />
                </Suspense>
              }
            />
            <Route
              path='/admin/repairer/reject'
              element={
                <Suspense fallback={<LoadingIndicator />}>
                  <RepairerReject />
                </Suspense>
              }
            />
            <Route
              path='/admin/surveyor'
              element={
                <Suspense fallback={<LoadingIndicator />}>
                  <Surveyor />
                </Suspense>
              }
            />
            <Route
              path='/admin/surveyor/create'
              element={
                <Suspense fallback={<LoadingIndicator />}>
                  <SurveyorCreate />
                </Suspense>
              }
            />
            <Route
              path='/admin/surveyor/show'
              element={
                <Suspense fallback={<LoadingIndicator />}>
                  <SurveyorShow />
                </Suspense>
              }
            />
            <Route
              path='/admin/surveyor/edit'
              element={
                <Suspense fallback={<LoadingIndicator />}>
                  <SurveyorEdit />
                </Suspense>
              }
            />
            <Route
              path='/admin/surveyor/map'
              element={
                <Suspense fallback={<LoadingIndicator />}>
                  <SurveyorMap />
                </Suspense>
              }
            />
            <Route
              path='/admin/surveyor/reject'
              element={
                <Suspense fallback={<LoadingIndicator />}>
                  <SurveyorReject />
                </Suspense>
              }
            />
            <Route
              path='/admin/vehicle'
              element={
                <Suspense fallback={<LoadingIndicator />}>
                  <Vehicle />
                </Suspense>
              }
            />
            <Route
              path='/admin/vehicle/show'
              element={
                <Suspense fallback={<LoadingIndicator />}>
                  <VehicleShow />
                </Suspense>
              }
            />
            <Route
              path='/admin/vehicle/edit'
              element={
                <Suspense fallback={<LoadingIndicator />}>
                  <VehicleEdit />
                </Suspense>
              }
            />
            <Route
              path='/admin/location'
              element={
                <Suspense fallback={<LoadingIndicator />}>
                  <Location />
                </Suspense>
              }
            />
            <Route
              path='/admin/product'
              element={
                <Suspense fallback={<LoadingIndicator />}>
                  <Product />
                </Suspense>
              }
            />
            <Route
              path='/admin/product/show'
              element={
                <Suspense fallback={<LoadingIndicator />}>
                  <ProductShow />
                </Suspense>
              }
            />
            <Route
              path='/admin/product/edit'
              element={
                <Suspense fallback={<LoadingIndicator />}>
                  <ProductEdit />
                </Suspense>
              }
            />
            <Route
              path='/admin/account/billing'
              element={
                <Suspense fallback={<LoadingIndicator />}>
                  <Billing />
                </Suspense>
              }
            />
            <Route
              path='/admin/account/subscription'
              element={
                <Suspense fallback={<LoadingIndicator />}>
                  <Subscription />
                </Suspense>
              }
            />
            <Route
              path='/admin/reports'
              element={
                <Suspense fallback={<LoadingIndicator />}>
                  <Reports />
                </Suspense>
              }
            />
            <Route
              path='/admin/settings'
              element={
                <Suspense fallback={<LoadingIndicator />}>
                  <Settings />
                </Suspense>
              }
            />
            <Route
                path='/admin/client/user/creation'
                element={
                  <Suspense fallback={<LoadingIndicator />}>
                    <UserCreation />
                  </Suspense>
                }
              />
              <Route
                path='/admin/client/user/grid'
                element={
                  <Suspense fallback={<LoadingIndicator />}>
                    <UserGrid />
                  </Suspense>
                }
              />
              <Route
                path='/admin/client/surveyor/creation'
                element={
                  <Suspense fallback={<LoadingIndicator />}>
                    <SurveyorCreation />
                  </Suspense>
                }
              />
              <Route
                path='/admin/client/surveyor/grid'
                element={
                  <Suspense fallback={<LoadingIndicator />}>
                    <SurveyorGrid />
                  </Suspense>
                }
              />
              <Route
                path='/admin/client/repairer/creation'
                element={
                  <Suspense fallback={<LoadingIndicator />}>
                    <RepairerCreation />
                  </Suspense>
                }
              />
              <Route
                path='/admin/client/repairer/grid'
                element={
                  <Suspense fallback={<LoadingIndicator />}>
                    <RepairerGrid />
                  </Suspense>
                }
              />
              <Route
                path='/admin/client/newclaim/creation'
                element={
                  <Suspense fallback={<LoadingIndicator />}>
                    <NewClaim />
                  </Suspense>
                }
              />
              <Route
                path='/admin/client/claimverify/grid'
                element={
                  <Suspense fallback={<LoadingIndicator />}>
                    <ClaimGrid />
                  </Suspense>
                }
              />
              <Route
                path='/admin/client/claimverify/progress'
                element={
                  <Suspense fallback={<LoadingIndicator />}>
                    <ClaimVerify />
                  </Suspense>
                }
              />
              <Route
                path='/admin/client/claimverify/recorrection'
                element={
                  <Suspense fallback={<LoadingIndicator />}>
                    <ClaimCorrection />
                  </Suspense>
                }
              />
              <Route
                path='/admin/client/claimverify/claimqc'
                element={
                  <Suspense fallback={<LoadingIndicator />}>
                    <ClaimFormQC />
                  </Suspense>
                }
              />
              <Route
                path='/admin/client/claimverify/claimqcsurveyor'
                element={
                  <Suspense fallback={<LoadingIndicator />}>
                    <ClaimFormQCSurveyor />
                  </Suspense>
                }
              />
              <Route
                path='/admin/client/claimverify/surveyorreport'
                element={
                  <Suspense fallback={<LoadingIndicator />}>
                    <SurveyorReport />
                  </Suspense>
                }
              />
              <Route
                path='/admin/client/claimverify/surveyorforrepair'
                element={
                  <Suspense fallback={<LoadingIndicator />}>
                    <SurveyorReportRepair />
                  </Suspense>
                }
              />
              <Route
                path='/admin/client/claimverify/repairerreport'
                element={
                  <Suspense fallback={<LoadingIndicator />}>
                    <RepairerReport />
                  </Suspense>
                }
              />
              <Route
                path='/admin/client/claimverify/customerdetails'
                element={
                  <Suspense fallback={<LoadingIndicator />}>
                    <CustomerDetails />
                  </Suspense>
                }
              />
              <Route
                path='/admin/client/claimverify/customerinfo'
                element={
                  <Suspense fallback={<LoadingIndicator />}>
                    <CustomerInfo />
                  </Suspense>
                }
              />
              <Route
                path='/admin/client/claimverify/intimation'
                element={
                  <Suspense fallback={<LoadingIndicator />}>
                    <IntimationClaim />
                  </Suspense>
                }
              />
              <Route
                path='/admin/client/claimverify/claiminsurer'
                element={
                  <Suspense fallback={<LoadingIndicator />}>
                    <ClaimInsurer />
                  </Suspense>
                }
              />
              </Route>
          <Route path='*' exact={true} element={404} />
        </Routes>
      </BrowserRouter>
    </>
  );
};
