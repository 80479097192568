import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  gridData: [],
  sortOrder: { field: 'category', asc: true },
  filter: {
    category: '',
    name: '',
    perCasePriceForClient: '',
    perCasePriceForSurveyor: ''
  },
  currentPage: 1,
  itemsPerPage: 10
};

const productListSlice = createSlice({
  name: 'productGrid',
  initialState,
  reducers: {
    setGridData: (state, action) => {
      state.gridData = action.payload;
    },
    setSortOrder: (state, action) => {
      state.sortOrder = action.payload;
    },
    setFilter: (state, action) => {
      state.filter = { ...state.filter, ...action.payload };
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    }
  }
});

export const {
  setGridData,
  setSortOrder,
  setFilter,
  setCurrentPage
  } =
productListSlice.actions;

export default productListSlice.reducer;
