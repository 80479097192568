import { createSlice } from '@reduxjs/toolkit';

const surveyorSlice = createSlice({
  name: 'surveyor',
  initialState: {
    NameError: '',
    FosreportingError: '',
    AddressError: '',
    CityError: '',
    StateError: '',
    ZoneError: '',
    CountryError: '',
    PincodeError: '',
    AadhaarcardError: '',
    AadhaarcopyError: '',
    PancardError: '',
    PancopyError: '',
    DrivinglicensetypeError: '',
    DrivinglicensevalidityError: '',
    DrivinglicensecopyError: '',
    GridNameError: '',
    GridcategoryError: '',
    GridtypeError: '',
    GridlicencenumberError: '',
    GridlicencecopyError: '',
    GridcontactnumberError: '',
    GridmailError: '',
    GridgeolocationError: '',
    PermittedusersError: '',
    SelectproductsError: ''
  },
  reducers: {
    setNameError: (state, action) => {
      state.NameError = { name: action.payload };
    },
    clearNameError: (state) => {
      state.NameError = {};
    },
    setFosreportingError: (state, action) => {
      state.FosreportingError = { name: action.payload };
    },
    clearFosreportingError: (state) => {
      state.FosreportingError = {};
    },
    setAddressError: (state, action) => {
      state.AddressError = { name: action.payload };
    },
    clearAddressError: (state) => {
      state.AddressError = {};
    },
    setCityError: (state, action) => {
      state.CityError = { name: action.payload };
    },
    clearCityError: (state) => {
      state.CityError = {};
    },
    setStateError: (state, action) => {
      state.StateError = { name: action.payload };
    },
    clearStateError: (state) => {
      state.StateError = {};
    },
    setZoneError: (state, action) => {
      state.ZoneError = { name: action.payload };
    },
    clearZoneError: (state) => {
      state.ZoneError = {};
    },
    setCountryError: (state, action) => {
      state.CountryError = { name: action.payload };
    },
    clearCountryError: (state) => {
      state.CountryError = {};
    },
    setPincodeError: (state, action) => {
      state.PincodeError = { name: action.payload };
    },
    clearPincodeError: (state) => {
      state.PincodeError = {};
    },
    setAadhaarcardError: (state, action) => {
      state.AadhaarcardError = { name: action.payload };
    },
    clearAadhaarcardError: (state) => {
      state.AadhaarcardError = {};
    },
    setAadhaarcopyError: (state, action) => {
      state.AadhaarcopyError = { name: action.payload };
    },
    clearAadhaarcopyError: (state) => {
      state.AadhaarcopyError = {};
    },
    setPancardError: (state, action) => {
      state.PancardError = { name: action.payload };
    },
    clearPancardError: (state) => {
      state.PancardError = {};
    },
    setPancopyError: (state, action) => {
      state.PancopyError = { name: action.payload };
    },
    clearPancopyError: (state) => {
      state.PancopyError = {};
    },
    setDrivinglicensetypeError: (state, action) => {
      state.DrivinglicensetypeError = { name: action.payload };
    },
    clearDrivinglicensetypeError: (state) => {
      state.DrivinglicensetypeError = {};
    },
    setDrivinglicensevalidityError: (state, action) => {
      state.DrivinglicensevalidityError = { name: action.payload };
    },
    clearDrivinglicensevalidityError: (state) => {
      state.DrivinglicensevalidityError = {};
    },
    setDrivinglicensecopyError: (state, action) => {
      state.DrivinglicensecopyError = { name: action.payload };
    },
    clearDrivinglicensecopyError: (state) => {
      state.DrivinglicensecopyError = {};
    },
    setGridNameError: (state, action) => {
      state.GridNameError = { name: action.payload };
    },
    clearGridNameError: (state) => {
      state.GridNameError = {};
    },
    setGridcategoryError: (state, action) => {
      state.GridcategoryError = { name: action.payload };
    },
    clearGridcategoryError: (state) => {
      state.GridcategoryError = {};
    },
    setGridtypeError: (state, action) => {
      state.GridtypeError = { name: action.payload };
    },
    clearGridtypeError: (state) => {
      state.GridtypeError = {};
    },
    setGridlicencenumberError: (state, action) => {
      state.GridlicencenumberError = { name: action.payload };
    },
    clearGridlicencenumberError: (state) => {
      state.GridlicencenumberError = {};
    },
    setGridlicencecopyError: (state, action) => {
      state.GridlicencecopyError = { name: action.payload };
    },
    clearGridlicencecopyError: (state) => {
      state.GridlicencecopyError = {};
    },
    setGridcontactnumberError: (state, action) => {
      state.GridcontactnumberError = { name: action.payload };
    },
    clearGridcontactnumberError: (state) => {
      state.GridcontactnumberError = {};
    },
    setGridmailError: (state, action) => {
      state.GridmailError = { name: action.payload };
    },
    clearGridmailError: (state) => {
      state.GridmailError = {};
    },
    setGridgeolocationError: (state, action) => {
      state.GridgeolocationError = { name: action.payload };
    },
    clearGridgeolocationError: (state) => {
      state.GridgeolocationError = {};
    },
    setPermittedusersError: (state, action) => {
      state.PermittedusersError = { name: action.payload };
    },
    clearPermittedusersError: (state) => {
      state.PermittedusersError = {};
    },
    setSelectproductsError: (state, action) => {
      state.SelectproductsError = { name: action.payload };
    },
    clearSelectproductsError: (state) => {
      state.SelectproductsError = {};
    }
  }
});

export const {
  setPancardError,
  clearPancardError,
  setPancopyError,
  clearPancopyError,
  setDrivinglicensetypeError,
  clearDrivinglicensetypeError,
  setDrivinglicensevalidityError,
  clearDrivinglicensevalidityError,
  setDrivinglicensecopyError,
  clearDrivinglicensecopyError,
  setGridNameError,
  clearGridNameError,
  setGridcategoryError,
  clearGridcategoryError,
  setGridtypeError,
  clearGridtypeError,
  setGridlicencenumberError,
  clearGridlicencenumberError,
  setGridlicencecopyError,
  clearGridlicencecopyError,
  setGridcontactnumberError,
  clearGridcontactnumberError,
  setGridmailError,
  clearGridmailError,
  setGridgeolocationError,
  clearGridgeolocationError,
  setPermittedusersError,
  clearPermittedusersError,
  setSelectproductsError,
  clearSelectproductsError,
  setAadhaarcopyError,
  clearAadhaarcopyError,
  setAadhaarcardError,
  clearAadhaarcardError,
  setPincodeError,
  clearPincodeError,
  setCountryError,
  clearCountryError,
  setZoneError,
  clearZoneError,
  setStateError,
  clearStateError,
  setCityError,
  clearCityError,
  setAddressError,
  clearAddressError,
  setNameError,
  clearNameError,
  setFosreportingError,
  clearFosreportingError
} = surveyorSlice.actions;

export default surveyorSlice.reducer;
